var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isInDashboard || _vm.isHovered,
            expression: "!isInDashboard || isHovered",
          },
        ],
        class: {
          "has-logo": _vm.showLogo,
          "sidebar-inner": true,
        },
        on: { mouseleave: _vm.handleMouseLeave },
      },
      [
        _vm.showLogo
          ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-scrollbar",
          { attrs: { "wrap-class": "scrollbar-wrapper" } },
          [
            _c(
              "el-menu",
              {
                attrs: {
                  "default-active": _vm.activeMenu,
                  collapse: _vm.isCollapse,
                  "background-color": _vm.variables.menuBg,
                  "text-color": _vm.variables.menuText,
                  "unique-opened": true,
                  "active-text-color": _vm.settings.theme,
                  "collapse-transition": false,
                  mode: "vertical",
                },
              },
              _vm._l(_vm.permission_routes, function (route) {
                return _c("sidebar-item", {
                  key: route.path,
                  attrs: { item: route, "base-path": route.path },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isInDashboard && !_vm.isHovered
      ? _c("div", {
          staticClass: "sidebar-trigger",
          on: { mouseenter: _vm.handleMouseEnter },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }