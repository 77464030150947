import request from '@/utils/request'
const scope = 'server'

// 登录方法
export function login(username, password, time, code, realKey) {
  const grant_type = 'password'

  // 创建表单数据
  const formData = new URLSearchParams()
  formData.append('username', username)
  formData.append('password', password)
  formData.append('grant_type', grant_type)
  formData.append('scope', scope)
  formData.append('time', time)

  return request({
    url: `/oauth/token?code=${code}&realKey=${realKey}`,
    headers: {
      isToken: false,
      'Authorization': 'Basic bWlwczplbnRmcm0=',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    data: formData
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'

  return request({
    url: '/oauth/token',
    headers: {
      'isToken': false,
      'Authorization': 'Basic bWlwczplbnRmcm0=',
    },
    method: 'post',
    params: { refresh_token, grant_type, scope }
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/info',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/token/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captcha/image/'+Date.now(),
    method: 'get'
  })
}
