import { login, logout, getInfo } from '@/api/login'
import { getAccessToken, setAccessToken, removeAccessToken, getRefreshToken, setRefreshToken, removeRefreshToken } from '@/utils/auth'
import { Message } from 'element-ui'

const user = {
  state: {
    access_token: getAccessToken(),
    refresh_token: getRefreshToken(),
    name: '',
    avatar: '',
    deptName: '',
    roles: [],
    permissions: []
  },

  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_DEPT_NAME: (state, deptName) => {
      state.deptName = deptName
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const realKey = userInfo.realKey
      const time = userInfo.time

      return new Promise((resolve, reject) => {
        if (!code || !realKey) {
          reject(new Error('验证码信息不完整'));
          return;
        }

        login(username, password, time, code, realKey).then(response => {

          if (!response || !response.access_token) {
            reject(new Error('登录失败：未获取到访问令牌'));
            return;
          }

          setAccessToken(response.access_token);
          commit('SET_ACCESS_TOKEN', response.access_token);

          if (response.refresh_token) {
            setRefreshToken(response.refresh_token);
            commit('SET_REFRESH_TOKEN', response.refresh_token);
          }

          resolve(response);
        }).catch(error => {
          console.error('Login error:', error);
          if (error.response && error.response.status === 428) {
            reject(new Error('验证码错误或已过期，请重新输入'));
          } else if (error.response && error.response.data && error.response.data.msg) {
            reject(new Error(error.response.data.msg));
          } else {
            reject(new Error('登录失败，请重试'));
          }
        });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.access_token) {
          reject(new Error('获取用户信息失败：未登录状态'));
          return;
        }

        getInfo().then(response => {
          if (!response.data) {
            reject(new Error('获取用户信息失败：响应数据为空'));
            return;
          }

          const user = response.data;
          if (!user) {
            reject(new Error('验证失败，请重新登录'));
            return;
          }

          const avatar = user.avatar == "" ? require("@/assets/images/profile.gif") : process.env.VUE_APP_BASE_API + user.avatar;

          if (user.roleList && user.roleList.length > 0) {
            commit('SET_ROLES', user.roleList);
            commit('SET_PERMISSIONS', user.permissions);
          } else {
            console.warn('GetInfo - No roles found, setting default role');
            commit('SET_ROLES', ['ROLE_DEFAULT']);
          }

          commit('SET_NAME', user.userName);
          commit('SET_AVATAR', avatar);
          commit('SET_DEPT_NAME', user.deptName);
          resolve(response);
        }).catch(error => {
          console.error('GetInfo - Error:', error);
          reject(error);
        });
      });
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_ACCESS_TOKEN', '')
          commit('SET_REFRESH_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeAccessToken()
          removeRefreshToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        removeAccessToken()
        removeRefreshToken()
        resolve()
      })
    }
  }
}

export default user
