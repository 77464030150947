<template>
  <div>
    <!-- 侧边栏主体 -->
    <div 
      v-show="!isInDashboard || isHovered"
      :class="{
        'has-logo': showLogo,
        'sidebar-inner': true
      }"
      @mouseleave="handleMouseLeave"
    >
      <logo v-if="showLogo" :collapse="isCollapse" />
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
          :default-active="activeMenu"
          :collapse="isCollapse"
          :background-color="variables.menuBg"
          :text-color="variables.menuText"
          :unique-opened="true"
          :active-text-color="settings.theme"
          :collapse-transition="false"
          mode="vertical"
        >
          <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
        </el-menu>
      </el-scrollbar>
    </div>

    <!-- 触发区域，只在智能看板页面显示 -->
    <div 
      v-if="isInDashboard && !isHovered"
      class="sidebar-trigger"
      @mouseenter="handleMouseEnter"
    ></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/assets/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      isHovered: false
    }
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    isInDashboard() {
      return this.$route.path.includes('/dashboard')
    }
  },
  methods: {
    handleMouseEnter() {
      if (this.isInDashboard) {
        this.isHovered = true
      }
    },
    handleMouseLeave() {
      if (this.isInDashboard) {
        this.isHovered = false
      }
    }
  },
  watch: {
    '$route.path': {
      handler(newPath) {
        if (newPath.includes('/dashboard')) {
          this.isHovered = false
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.sidebar-trigger {
  position: fixed;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  z-index: 1001;
  background: transparent;
  cursor: pointer;
}

.sidebar-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $menuBg;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

// reset element-ui css
.horizontal-collapse-transition {
  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
}

.scrollbar-wrapper {
  overflow-x: hidden !important;
  height: 100%;
}

.el-scrollbar__bar.is-vertical {
  right: 0px;
}

.el-scrollbar {
  height: 100%;
}

.has-logo {
  .el-scrollbar {
    height: calc(100% - 50px);
  }
}

.is-horizontal {
  display: none;
}

a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

.svg-icon {
  margin-right: 16px;
}

.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}

.submenu-title-noDropdown,
.el-submenu__title {
  &:hover {
    background-color: $menuHover !important;
  }
}

.is-active > .el-submenu__title {
  color: $subMenuActiveText !important;
}

.nest-menu .el-submenu > .el-submenu__title,
.el-menu-item {
  min-width: 210px !important;
  background-color: $subMenuBg !important;

  &:hover {
    background-color: $subMenuHover !important;
  }
}
</style>
